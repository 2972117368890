.member-image {
  width: 300px;
  height: 276px;
}

.shortcut-button {
  width: 40px;
  height: 40px;
  max-width: 40px;
}

.member-name {
  font-size: 20px;
}

.member-text {
  font-size: 18px;
}
