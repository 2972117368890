p {
  color: #846d57;
}

.GoToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 64px;
  height: 64px;
  display: none;
  background-image: url("./up_page_arrow.webp");
  background-color: #b39c86f0;
  color: #846d57;
  border: none;
}

.fixedSection {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
  background-color: #b39c86f0;
}
