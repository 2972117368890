.footerrow{
    background-color:#846d57;
    color:#846d57; 
}

.footertext{
    color: white;
    font-size:1vw;
}

.footertextlink{
    color: white;
    font-size:1.1vw;
}