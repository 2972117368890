.duodentTextSection {
  background-color: #846d57;
}

.duodentText {
  color: aliceblue;
}

.duodentTextBrown {
  color: #846d57;
}

.addresslink {
  color: aliceblue;
  width: fit-content;
}
