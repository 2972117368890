.button {
  border: none;
  background-color: transparent;
}

.social-media-icons {
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
}

.social-media-icons-mobile {
  width: 25px;
  height: 25px;
  min-width: 25px;
  max-width: 25px;
}

.logo {
  width: 200px;
  height: 50px;
  background-color: transparent;
}

.logo-min {
  width: 150px;
  height: 40px;
  background-color: transparent;
}
